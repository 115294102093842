<template>
    <div class="global-content">
        <div v-if="control.controlDevShow == '1'" class="block global-margin-bottom global-shadow">
            <div><i class="fa fa-support global-title-fa"></i><span class="global-title">节目控制</span></div>
            <van-divider />
            <div class="info">
                <van-row>
                    <van-col span="6" v-for="(item,i) in programData" :key="item.code" @click="selectItem(item,i,'program')" :class="configItemIndex1 == i? 'active':'' ">
                        <div><i class="fa fa-dashboard "></i></div>
                        <div class="text" v-text="item.name"></div>
                    </van-col>
                </van-row>
            </div>
        </div>
        <div v-if="control.controlDevLight == '1'" class="block global-margin-bottom global-shadow">
            <div><i class="fa fa-lightbulb-o global-title-fa-big"></i><span class="global-title">照明控制</span></div>
            <van-divider />
            <div class="info">
                <van-row>
                    <van-col class="line-block" span="12" v-for="(item,i) in lightData" :key="item.itemCode" @click="selectItem(item.itemCode,i,'light')" :class="configItemIndex2 == i? 'active':'' ">
                        <div><i class="fa fa-th-large"></i><span class="t-right" v-text="item.itemName"></span></div>
                    </van-col>
                </van-row>
            </div>
        </div>
        <div v-if="control.controlDevMediaPeriod == '1' || control.integer == '1' || control.controlMultiMedia == '1'" class="block global-shadow">
            <div><i class="fa fa-video-camera global-title-fa"></i><span class="global-title">媒体控制<span class="global-remark">（进入详情面板设置）</span></span></div>
            <van-divider />
            <div class="info">
                <van-row>
                    <div v-for="(item,i) in mediaData" :key="item.id">
                        <van-col span="8" v-if="item.control == '1'"  @click="goMedia(item.id,i)" :class="configItemIndex3 == i? 'active':'' ">
                                <div><i :class="'fa '+item.icon"></i></div>
                                <div class="text" v-text="item.value"></div>
                        </van-col>
                    </div>
                </van-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ControlDev',
    data(){
        return{
            programData:[],
            lightData:[],
            configItemIndex1:0,
            configItemIndex2:0,
            configItemIndex3:0,
            isAisleP:true,
            isAisleL:true,
            control:{
                controlDevShow:'0',
                controlDevLight:'0',
                controlDevMediaPeriod:'0',
                controlRtcAudio:'0',
                controlMultiMedia:'0'
            }
        }
    },
    created(){
        window.document.title = this.$route.query.devCode+'-设备控制';
        let control = window.sessionStorage.getItem('control');
        this.control = {...JSON.parse(control)};  //赋值
        if(this.control.controlDevShow=='1'&&this.control.controlDevLight=='1'){
            let pro = this.getPro()
            let lig = this.getLig()
            Promise.all([pro, lig]).then(res => {
                this.getNow()
            })
        }
        if(this.control.controlDevShow=='1'&&this.control.controlDevLight!='1'){
            this.getPro().then(res=>{
                this.getNow()
            })
        }
        if(this.control.controlDevShow!='1'&&this.control.controlDevLight=='1'){
            this.getLig().then(res=>{
                this.getNow()
            })
        }
    },
    beforeDestroy(){
        // sessionStorage.removeItem("control"); //修复-删除缓存后退页面 无法判断显示的内容
    },
    computed:{
        mediaData(){
            let data =[{id:1,value:'视频预览',icon:'fa-youtube-play'},{id:2,value:'周期拍照',icon:'fa-picture-o'},{id:3,value:'语音对讲',icon:'fa-microphone'}];
            data.forEach((element,index) => {
                if(index==0){
                    this.control.controlMultiMedia == '1' ? element.control = '1' : element.control = '0'
                }else if(index==1){
                    this.control.controlDevMediaPeriod == '1' ? element.control = '1' : element.control = '0'
                }else{
                    this.control.controlRtcAudio == '1' ? element.control = '1' : element.control = '0'
                }
            });
            return data
        }
    },
    methods:{
        // 获取节目控制选项
        getPro(){
            return new Promise((resolve,reject)=>{
                this.$api.ITGS.getProgramList().then((data)=>{
                    this.programData = data;
                    resolve()
                })
            })
        },
        // 获取灯光控制选项
        getLig(){
            return new Promise((resolve,reject)=>{
                this.$api.ITGS.getControlDict({typeCode:'control_light'}).then((data)=>{
                    this.lightData = data;
                    resolve()
                })
            })
        },
        // 获取最新设备选项
        getNow(){
            return new Promise((resolve,reject)=>{
                this.$api.ITGS.getAppBeat({devCode:this.$route.query.devCode}).then((data)=>{
                    if(data){
                       data.progState ? this.configItemIndex1 = this.programData.findIndex((i)=> data.progState == i.code) : '';
                       data.lightState ? this.configItemIndex2 = this.lightData.findIndex((i)=> data.lightState == i.itemCode) : '';
                    }else{
                        this.configItemIndex1 = '0';
                        this.configItemIndex2 = '0';
                    }
                })
            })
        },
        selectItem(code,i,type){
            switch(type){
                case 'program':
                    if(this.isAisleP){
                        this.isAisleP = false;
                        this.configItemIndex1 =i; 
                        this.$api.ITGS.subProgram({devCode:this.$route.query.devCode,progCode:code.code,progPath:code.url}).then((data)=>{
                            this.$toast('提交成功');
                        })
                        const timeLimitP = setTimeout(()=>{
                            this.isAisleP = true;
                            clearTimeout(timeLimitP)
                        },5000)
                    }else{
                        this.$toast('操作频率过快，请在5秒之后再试')
                    }
                    break;
                case 'light':
                    if(this.isAisleL){
                        this.isAisleL = false;
                        this.configItemIndex2 =i; 
                        this.$api.ITGS.subLight({devCode:this.$route.query.devCode,controlModel:code}).then((data)=>{
                            this.$toast('提交成功');
                        })
                        const timeLimitL = setTimeout(()=>{
                            this.isAisleL = true;
                            clearTimeout(timeLimitL)
                        },5000)
                    }else{
                        this.$toast('操作频率过快，请在5秒之后再试')
                    }
                    break;
            }
        },
        goMedia(id,i){
            this.configItemIndex3 =i; 
            if(i==0){
                this.$router.push({path:"/itgs/control/Video", query:{"devCode":this.$route.query.devCode}})
            }else if(i==1){
                this.$router.push({path:"/itgs/control/Picture", query:{"devCode":this.$route.query.devCode}})
            }else{
                this.$router.push({path:"/itgs/control/Chat", query:{"devCode":this.$route.query.devCode}})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.block{
    background-color: #fff;
    padding: 2%;
    border-radius: 10px;
    .info{
        color:#656565;
        text-align: center;
        overflow-y: scroll;
        max-height: 15rem;
        .active{color: #0957ff;}
        .line-block{text-align: left;padding-left: 5%;}
        .van-col{margin-bottom: 8%;}
        .fa{font-size: 1.5rem;vertical-align:middle}
        .text{font-size: .8rem;padding-top: 4px;height: 2rem;}
        .t-right{line-height: 2;padding-left: 8px;font-size: .85rem;}
    }
}
</style>